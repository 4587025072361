import * as React from "react"
import { graphql } from "gatsby"
import { Layout } from "../components/layout"
import { Seo } from "../components/seo"
import { Wrapper } from "../components/wrapper"

const WpPageTemplate = ({ data, location }) => {
  const { date, dateJP, modified, modifiedJP, title, featuredImage, content } =
    data.wpPage

  return (
    <Layout>
      <Seo
        pagetitle={title}
        pagedesc={content
          .replace(/<("[^"]*"|'[^']*'|[^'">])*>/g, "")
          .slice(0, 70)}
        pagepath={location.pathname}
        blogimg={featuredImage?.node.localFile.childImageSharp.gatsbyImageData}
        pageimgw={featuredImage?.node.mediaDetails.width}
        pageimgh={featuredImage?.node.mediaDetails.height}
        publishDate={date}
        updateDate={modified}
      />

      <Wrapper
        date={date}
        dateJP={dateJP}
        modified={modified}
        modifiedJP={modifiedJP}
        title={title}
        featuredImage={featuredImage}
        image={featuredImage.node.localFile.childImageSharp.gatsbyImageData}
        alt={title}
        content={content}
      />
    </Layout>
  )
}

export default WpPageTemplate

export const query = graphql`
  query ($id: String!) {
    wpPage(id: { eq: $id }) {
      title
      content
      date
      dateJP: date(formatString: "YYYY/MM/DD")
      modified
      modifiedJP: modified(formatString: "YYYY/MM/DD")
      featuredImage {
        node {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
          sourceUrl
          title
          altText
          mediaDetails {
            width
            height
          }
        }
      }
    }
  }
`
